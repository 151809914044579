const Legend = (props) => {
  const oppDivs = props.opportunityCategories.map((item, index) => {
    return (
      <div
        key={index}
        style={{ fontSize: 12, textAlign: "left", marginLeft: "1px" }}
      >
        <span
          style={{
            backgroundColor: item[1],
            display: "inline-block",
            height: "15px",
            marginRight: "5px",
            marginLeft: "1px",
            width: "15px",
            borderRadius: "50%",
          }}
        ></span>
        {item[0]}
      </div>
    );
  });

  const blockGroupLegendItems = [
    "Very Low",
    "Low",
    "Moderate",
    "High",
    "Very High",
  ];

  const makeTransitDivs = (isLayerVisible) => {
    let dash = isLayerVisible["dash_lines"];
    let commuter = isLayerVisible["commuter_lines"];
    let bus = isLayerVisible["bus_lines"];
    let metro = isLayerVisible["metro_lines"];
    let divs = [];

    if (dash) {
      divs.push(
        <div
          key={0}
          style={{ fontSize: 12, textAlign: "left", marginLeft: "1px" }}
        >
          <span
            style={{
              backgroundColor: "rgb(252,141,98)",
              display: "inline-block",
              height: "15px",
              marginRight: "5px",
              marginLeft: "1px",
              width: "15px",
              borderRadius: "50%",
            }}
          ></span>
          Community Dash
        </div>
      );
      divs.push(
        <div
          key={1}
          style={{ fontSize: 12, textAlign: "left", marginLeft: "1px" }}
        >
          <span
            style={{
              backgroundColor: "rgb(255, 255, 179)",
              display: "inline-block",
              height: "15px",
              marginRight: "5px",
              marginLeft: "1px",
              width: "15px",
              borderRadius: "50%",
            }}
          ></span>
          Downtown Dash
        </div>
      );
    }
    if (commuter) {
      divs.push(
        <div
          key={3}
          style={{ fontSize: 12, textAlign: "left", marginLeft: "1px" }}
        >
          <span
            style={{
              backgroundColor: "rgba(49,163,84,0.7)",
              display: "inline-block",
              height: "15px",
              marginRight: "5px",
              marginLeft: "1px",
              width: "15px",
              borderRadius: "50%",
            }}
          ></span>
        </div>
      );
    }

    if (bus) {
      divs.push(
        <div
          key={4}
          style={{ fontSize: 12, textAlign: "left", marginLeft: "1px" }}
        >
          <span
            style={{
              backgroundColor: "#696969",
              display: "inline-block",
              height: "15px",
              marginRight: "5px",
              marginLeft: "1px",
              width: "15px",
              borderRadius: "50%",
            }}
          ></span>
          Bus Routes
        </div>
      );
    }
    if (metro) {
      divs.push(
        <div
          key={5}
          style={{ fontSize: 12, textAlign: "left", marginLeft: "20px" }}
        >
          Metro Rail
          <br />
          <span
            style={{
              backgroundColor: "#10C4FF",
              display: "inline-block",
              height: "15px",
              marginRight: "5px",
              marginLeft: "0px",
              width: "15px",
              borderRadius: "50%",
            }}
          ></span>
          <span
            style={{
              backgroundColor: "#B634BB",
              display: "inline-block",
              height: "15px",
              marginRight: "5px",
              marginLeft: "1px",
              width: "15px",
              borderRadius: "50%",
            }}
          ></span>
          <span
            style={{
              backgroundColor: "#0073CF",
              display: "inline-block",
              height: "15px",
              marginRight: "5px",
              marginLeft: "1px",
              width: "15px",
              borderRadius: "50%",
            }}
          ></span>
          <span
            style={{
              backgroundColor: "#EF3E42",
              display: "inline-block",
              height: "15px",
              marginRight: "5px",
              marginLeft: "1px",
              width: "15px",
              borderRadius: "50%",
            }}
          ></span>
          <span
            style={{
              backgroundColor: "#61C24F",
              display: "inline-block",
              height: "15px",
              marginRight: "5px",
              marginLeft: "1px",
              width: "15px",
              borderRadius: "50%",
            }}
          ></span>
          <span
            style={{
              backgroundColor: "#EFAB00",
              display: "inline-block",
              height: "15px",
              marginRight: "5px",
              marginLeft: "1px",
              width: "15px",
              borderRadius: "50%",
            }}
          ></span>
        </div>
      );
    }
    return divs;
  };

  const blockgroupDivs = blockGroupLegendItems.map(function (item, index) {
    return (
      <div
        key={index}
        style={{
          fontSize: 12,
          textAlign: "left",
          marginLeft: "1px",
        }}
      >
        <span
          style={{
            backgroundColor: props.blockgroupColors[index],
            display: "inline-block",
            height: "15px",
            marginRight: "5px",
            marginLeft: "1px",
            width: "15px",
            borderRadius: "50%",
          }}
        ></span>
        {item}
      </div>
    );
  });

  let parcelDivs = "";
  let parcelLabel = "";
  let parcelPriorityLevels = props.priorityLegendMap;
  if (props.showThreshold) {
    parcelPriorityLevels.shift();
  }
  if (props.viewState.zoom > 14) {
    parcelDivs = parcelPriorityLevels.map((item, index) => {
      return (
        <div
          key={index}
          style={{ fontSize: 12, textAlign: "left", marginLeft: "1px" }}
        >
          <span
            style={{
              backgroundColor: item[1],
              display: "inline-block",
              height: "15px",
              marginRight: "5px",
              marginLeft: "1px",
              width: "15px",
              borderRadius: "50%",
            }}
          ></span>
          {item[0]}
        </div>
      );
    });
    parcelLabel = "Site Funding Ranking:";
  } else {
    parcelDivs = <div></div>;
  }

  let transitLabel = "";
  let transitDivs = makeTransitDivs(props.isLayerVisible);
  if (transitDivs.length > 0) {
    transitLabel = (
      <div style={{ fontSize: "16px", padding: "0px" }}>
        <hr style={{ padding: "0px" }} />
        Transit:
      </div>
    );
  }

  let narDivs = "";
  let narLabel = "";
  if (props.clickInfo && props.viewState.zoom > 14) {
    narDivs = (
      <div style={{ textAlign: "justify", padding: "5px" }}>
        {props.clickInfo.costPerUnit}
      </div>
    );
    narLabel = (
      <div style={{ fontSize: "16px", padding: "0px" }}>
        <hr style={{ padding: "0px" }} />
        Cost per Unit Analysis:
      </div>
    );
  }

  let aggDivs = "";
  let aggLabel = "";
  let aggContext = "";

  if (
    props.aggregateLayer === "blockgroup_aggregation" ||
    props.aggregateLayer === "blockgroup_concentration"
  ) {
    if (props.viewState.zoom > 14) {
      aggDivs = <div></div>;
    } else {
      aggDivs = blockgroupDivs;
      aggLabel = "Concentration of Suitable Sites:";
      aggContext =
        props.aggregateLayer === "blockgroup_concentration" ? (
          <div>
            <hr />{" "}
            <p>
              This map shows the concentration of moderately and highly suitable
              sites per block group.
            </p>
          </div>
        ) : (
          <div>
            <hr /> <p>This map shows the count of sites per block group.</p>
          </div>
        );
    }
  } else if (props.aggregateLayer === "opportunity_layer") {
    aggDivs = oppDivs;
    aggLabel =
      props.viewState.zoom > 14 ? (
        <div>
          <hr />
          TCAC Opportunity Categories
        </div>
      ) : (
        <div>TCAC Opportunity Categories</div>
      );
    aggContext = (
      <div>
        <hr /> <p>This map shows the TCAC Opportunity Categories.</p>
      </div>
    );
  } else {
    aggDivs = <div></div>;
    aggLabel = "Select a Summary Level for more details";
  }
  let highlightDivs = "";

  function formatNumber(num) {
    return num.toLocaleString("en-US", {
      maximumFractionDigits: 1,
    });
  }

  if (props.clickedAggregate && props.viewState.zoom < 14) {
    let clickedAggregate = props.clickedAggregate;
    let label = "";
    if (clickedAggregate.layer.id === "council_layer") {
      label = "District " + clickedAggregate.properties.label;
    } else {
      label = clickedAggregate.properties.label;
    }

    let highSuitability = formatNumber(
      clickedAggregate.properties.highSuitability
    );

    let medSuitability = formatNumber(
      clickedAggregate.properties.medSuitability
    );

    let totParcels = formatNumber(clickedAggregate.properties.totalParcels);
    let highSuitabilityShare =
      clickedAggregate.properties.shareHigh.toFixed(1) + "%";
    highlightDivs = (
      <div>
        <hr style={{ marginTop: "5px", marginBottom: "5px" }} />
        <p style={{ textAlign: "left", paddingLeft: "5px", paddingTop: "0px" }}>
          <strong>{label}</strong>
          <br />
          <strong>Highly Suitable Sites: </strong>
          {highSuitability}
          <br />
          <strong>Moderately Suitable Sites: </strong>
          {medSuitability}
          <br />
          <strong>Total Sites: </strong>
          {totParcels}
          <br />
          <strong>Percent Highly Suitable Sites: </strong>
          {highSuitabilityShare}
        </p>
      </div>
    );
  }

  if (props.visible === true) {
    return (
      <div
        style={{
          width: "20%",
          padding: "5px 0px",
          textAlign: "center",
          borderStyle: "solid",
          fontSize: "14px",
          backgroundColor: "rgba(255, 255, 255, 0.85)",
          position: "absolute",
          top: 20,
          right: 20,
        }}
        className="rounded-3"
      >
        <div>{parcelLabel}</div>
        <div style={{ fontSize: "14px" }}>{parcelDivs}</div>
        <div>{narLabel}</div>
        <div style={{ fontSize: "14px" }}>{narDivs}</div>
        <div style={{ fontSize: "18px" }}>{aggLabel}</div>
        <div style={{ textAlign: "left" }}>{aggDivs}</div>
        <div
          style={{ fontSize: "14px", textAlign: "left", paddingLeft: "5px" }}
        >
          {aggContext}
        </div>
        <div style={{ fontSize: "14px", textAlign: "center" }}>
          {transitLabel}
        </div>
        <div style={{ textAlign: "left" }}>{transitDivs}</div>
        {props.clickedAggregate && highlightDivs}
      </div>
    );
  } else {
    return null;
  }
};

export default Legend;
