import Table from "react-bootstrap/Table";

const priorityLegendMap = {
  "Not Likely Suitable": "#fdbe85",
  "Low Suitability": "#fd8d3c",
  "Moderate Suitability": "#e6550d",
  "Highly Suitable": "#a63603",
};

const DataTable = (props) => {
  let clickInfo = props.clickInfo;
  if (clickInfo === null) {
    return (
      <div className="align-middle" style={{ textAlign: "center" }}>
        Interact with the MOST Map to view selected parcel data.
      </div>
    );
  } else {
    return (
      <div style={{ width: "100%" }}>
        <Table
          striped="True"
          bordered="True"
          hover="True"
          style={{ width: "100%", fontSize: 13 }}
          className="table-fixed"
        >
          <thead>
            <tr>
              <th
                colSpan={2}
                style={{
                  textAlign: "center",
                  backgroundColor: clickInfo.parcelThreshold
                    ? priorityLegendMap[clickInfo.parcelPriority]
                    : "rgb(105,105,105)",
                }}
              >
                {clickInfo.parcelPriority}
              </th>
            </tr>
            {clickInfo.parcelThreshold === false ? (
              <tr>
                <th colSpan={2} style={{ textAlign: "center" }}>
                  This tax lot is too small to accommodate market feasible
                  affordable development.
                </th>
              </tr>
            ) : (
              ""
            )}
          </thead>
          <tbody>
            <tr>
              <td style={{ width: "50%" }}>Parcel ID (APN)</td>
              <td className="align-middle" style={{ textAlign: "center" }}>
                {clickInfo.parcelId}
              </td>
            </tr>
            {clickInfo.parcelAddress !== " " ? (
              <tr>
                <td style={{ width: "50%" }}>Address</td>
                <td className="align-middle" style={{ textAlign: "center" }}>
                  {clickInfo.parcelAddress}
                </td>
              </tr>
            ) : (
              ""
            )}
            <tr>
              <td style={{ width: "50%" }}>Size</td>
              <td className="align-middle" style={{ textAlign: "center" }}>
                {clickInfo.parcelSize.toFixed(2)} Acres
              </td>
            </tr>
            <tr>
              <td style={{ width: "50%" }}>Zone</td>
              <td className="align-middle" style={{ textAlign: "center" }}>
                {clickInfo.parcelZone}
              </td>
            </tr>
            {clickInfo.capacity && (
              <tr>
                <td style={{ width: "50%" }}>Zoned Capacity</td>
                <td className="align-middle" style={{ textAlign: "center" }}>
                  {clickInfo.capacity}
                </td>
              </tr>
            )}
            <tr>
              <td style={{ width: "50%" }}>TCAC Opportunity Category</td>
              <td className="align-middle" style={{ textAlign: "center" }}>
                {clickInfo.oppCat}
              </td>
            </tr>
            <tr>
              <td style={{ width: "50%" }}>High Fire Risk?</td>
              <td className="align-middle" style={{ textAlign: "center" }}>
                {clickInfo.parcelFire}
              </td>
            </tr>
            <tr>
              <td style={{ width: "50%" }}>Coastal Zone?</td>
              <td className="align-middle" style={{ textAlign: "center" }}>
                {clickInfo.parcelCoastal}
              </td>
            </tr>

            <tr>
              <td style={{ width: "50%" }}>Council District</td>
              <td className="align-middle" style={{ textAlign: "center" }}>
                <a
                  href={clickInfo.district_link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {clickInfo.districtInfo}
                </a>
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    );
  }
};

const ParcelAnalysisTable = (props) => {
  let clickInfo = props.clickInfo;

  if (clickInfo === null) {
    return (
      <div className="align-middle" style={{ textAlign: "center" }}>
        Interact with the MOST Map to view selected parcel analysis.
      </div>
    );
  } else {
    let ahmp;
    if (clickInfo.AHMP < 2) {
      ahmp = "None";
    } else if (clickInfo.AHMP === 2) {
      ahmp = "3 out of 6 points";
    } else if (clickInfo.AHMP === 3) {
      ahmp = "5 out of 6 points";
    } else if (clickInfo.AHMP === 4) {
      ahmp = "6 out of 6 points";
    }

    let ahsc;
    if (clickInfo.AHSC_elig < 3) {
      ahsc = "No";
    } else if (clickInfo.AHSC_elig === 3) {
      ahsc = "ICP Only";
    } else if (clickInfo.AHSC_elig === 4) {
      ahsc = "ICP and TOD";
    }
    return (
      <div style={{ width: "100%" }}>
        <Table
          striped="True"
          bordered="True"
          hover="True"
          style={{
            width: "100%",
            fontSize: 13,
            padding: "0px",
            margin: "0px",
          }}
          className="table-fixed"
        >
          <thead></thead>
          <tbody style={{ backgroundColor: "rgba(69,69,69,.2)" }}>
            <tr>
              <td style={{ width: "50%" }}>LIHTC Max Amenity Points</td>
              <td className="align-middle" style={{ textAlign: "center" }}>
                {clickInfo.LIHTC_elig === 0 ? "No" : "Yes"}
              </td>
            </tr>
            <tr>
              <td style={{ width: "50%" }}>LIHTC Basis Boost</td>
              <td className="align-middle" style={{ textAlign: "center" }}>
                {clickInfo.LIHTC_BP === 0 ? "No" : "Yes"}
              </td>
            </tr>

            <tr>
              <td style={{ width: "50%" }}>AHMP Points</td>
              <td className="align-middle" style={{ textAlign: "center" }}>
                {ahmp}
              </td>
            </tr>
            <tr>
              <td style={{ width: "50%" }}>AHSC Eligible</td>
              <td className="align-middle" style={{ textAlign: "center" }}>
                {ahsc}
              </td>
            </tr>
            <tr>
              <td style={{ width: "50%" }}>AHSC Points</td>
              <td className="align-middle" style={{ textAlign: "center" }}>
                {clickInfo.AHSC_elig} out of 4 points
              </td>
            </tr>
            {clickInfo.costPerUnitRange && (
              <tr>
                <td style={{ width: "50%" }}>Estimated Cost Range per unit</td>
                <td className="align-middle" style={{ textAlign: "center" }}>
                  {clickInfo.costPerUnitRange}
                </td>
              </tr>
            )}
          </tbody>
          <thead>
            <tr>
              <th colSpan={2} style={{ textAlign: "center" }}>
                Parcel Characteristics
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={{ width: "50%" }}>In Qualified Census Tract (QCT)</td>
              <td className="align-middle" style={{ textAlign: "center" }}>
                {clickInfo.LIHTC_BP === 2 ? "Yes" : "No"}
              </td>
            </tr>
            <tr>
              <td style={{ width: "50%" }}>Difficult to Develop Area</td>
              <td className="align-middle" style={{ textAlign: "center" }}>
                {clickInfo.LIHTC_BP === 1 ? "Yes" : "No"}
              </td>
            </tr>
            <tr>
              <td style={{ width: "50%" }}>TCAC Opportunity Area</td>
              <td className="align-middle" style={{ textAlign: "center" }}>
                {clickInfo.oppCat}
              </td>
            </tr>
            <tr>
              <td style={{ width: "50%" }}>TOC Tier</td>
              <td className="align-middle" style={{ textAlign: "center" }}>
                {clickInfo.TOC === 2
                  ? "Tier 3 or 4"
                  : clickInfo.TOC === 1
                  ? "Tier 1 or 2"
                  : "Not in TOC"}
              </td>
            </tr>
            <tr>
              <td style={{ width: "50%" }}>Priority Population Area</td>
              <td className="align-middle" style={{ textAlign: "center" }}>
                {clickInfo.AHSC_prior === 1 ? "Yes" : "No"}
              </td>
            </tr>
            <tr>
              <td style={{ width: "50%" }}>AHSC ICP Eligible</td>
              <td className="align-middle" style={{ textAlign: "center" }}>
                {clickInfo.AHSC_elig > 3 ? "Yes" : "No"}
              </td>
            </tr>
            <tr>
              <td style={{ width: "50%" }}>AHSC TOD Eligible</td>
              <td className="align-middle" style={{ textAlign: "center" }}>
                {clickInfo.AHSC_elig === 4 ? "Yes" : "No"}
              </td>
            </tr>
            <tr>
              <td style={{ width: "50%" }}>In Opportunity Zone</td>
              <td className="align-middle" style={{ textAlign: "center" }}>
                {clickInfo.OPP_ZONE === 1 ? "Yes" : "No"}
              </td>
            </tr>
            <tr>
              <td style={{ width: "50%" }}>Ownership</td>
              <td className="align-middle" style={{ textAlign: "center" }}>
                {clickInfo.parcelPublic ? "Public" : "Private"}
              </td>
            </tr>
            <tr>
              <td style={{ width: "50%" }}>Nearest Transit Access</td>
              <td className="align-middle" style={{ textAlign: "center" }}>
                {clickInfo.nearestStop}
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    );
  }
};

export { DataTable, ParcelAnalysisTable };
