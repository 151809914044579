import "mapbox-gl/dist/mapbox-gl.css";
import React from "react";
import { render } from "react-dom";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import SurfaceMap from "./SurfaceMap/Map";

render(
  <SurfaceMap />,
  document.body.appendChild(document.createElement("div"))
);
