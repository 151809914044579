// import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import "mapbox-gl/dist/mapbox-gl.css";
import "../App.css";

import bus_stops from "../data/bus_stops.json";
import commuter_lines from "../data/commuter_lines.json";
import commuter_stops from "../data/commuter_stops.json";
import dash_lines from "../data/dash_lines.json";
import dash_stops from "../data/dash_stops.json";
import district_labels from "../data/district_labels.json";
import metro_stops from "../data/metro_stops.json";
import neighborhood_labels from "../data/neighborhood_labels.json";

import econw_logo from "./ECONW-circle logo-transparent.png";
import lahd_logo from "./lahd_logo.png";

import React, { useCallback, useRef, useState } from "react";
import Map, { Layer, NavigationControl, Popup, Source } from "react-map-gl";

import { Accordion, Button } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

import { DataTable, ParcelAnalysisTable } from "./DataTable.js";
import GeocoderControl from "./Geocoder.js";
import * as LayerStyles from "./Layers.js";
import Legend from "./Legend";
import { InfoModal, IntroModal } from "./Modals.js";
import { MapPopupDivs } from "./Popup.js";
const mapboxToken =
  "pk.eyJ1IjoibGFoZC1tYXBib3giLCJhIjoiY2xubm1jYmY2MDVyejJqcDZla2IycjdpMCJ9.KK-pprhvnwUI-LI-JEI0FA";

export default function SurfaceMap() {
  function getJurisdictionNames(jsonData) {
    let jurisdictionNames = [];
    for (var i in jsonData.features) {
      jurisdictionNames.push(jsonData.features[i].properties.label);
    }
    return jurisdictionNames;
  }
  const neighborhoods = getJurisdictionNames(neighborhood_labels);
  const districts = getJurisdictionNames(district_labels);

  const mapRef = useRef();
  const initialViewState = {
    latitude: 34.01402530204747,
    longitude: -118.40073935267027,
    zoom: 9,
  };
  const [viewState, setViewState] = useState(initialViewState);

  const [hoverInfo, setHoverInfo] = useState(null);
  const [clickInfo, setClickInfo] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [showLegend, setShowLegend] = useState(true);
  const [hoverLayers, setHoverLayers] = useState([]);

  const [mapStyle, setMapStyle] = useState("base");

  const [showInfoModal, setShowInfoModal] = useState(false);
  const handleCloseInfoModal = () => setShowInfoModal(false);

  const handleInfoModal = () => {
    setShowInfoModal(true);
    handleCloseIntroModal();
  };

  const beforeID = (mapStyle) => {
    if (mapStyle === "base") {
      return "road_major_label";
    } else {
      return "";
    }
  };

  const handleStyleChange = (e) => {
    let map = mapRef.current.getMap();
    if (mapStyle === "base") {
      setMapStyle("satellite");
      map.setStyle("mapbox://styles/mapbox/satellite-streets-v12");
    } else {
      setMapStyle("base");
      map.setStyle("mapbox://styles/lahd-mapbox/clnnm3szo00tt01q1ad3dbbbp");
    }
  };

  function buildFilterVar(filterList) {
    let filterNames = [];
    if (filterList.includes("Not Likely Suitable")) {
      filterNames.push("N");
    }
    if (filterList.includes("Low Suitability")) {
      filterNames.push("L");
    }
    if (filterList.includes("Moderate Suitability")) {
      filterNames.push("M");
    }
    if (filterList.includes("Highly Suitable")) {
      filterNames.push("H");
    }
    let filterName = "".concat(...filterNames);
    return filterName;
  }

  const allFilters = [
    "Not Likely Suitable",
    "Low Suitability",
    "Moderate Suitability",
    "Highly Suitable",
  ];

  const [priorityFilter, setPriorityFilter] = useState(allFilters);

  const [downloadFilter, setDownloadFilter] = useState(allFilters);

  const [downloadVar, setDownloadVar] = useState(buildFilterVar(allFilters));

  const [downloadJuris, setDownloadJuris] = useState("District 1");
  const [blockGroupFilter, setBlockgroupFilter] = useState(
    buildFilterVar(allFilters)
  );

  const aggregateLayers = [
    "blockgroup_aggregation",
    "opportunity_layer",
    "blockgroup_concentration",
  ];
  const outlineLayers = ["neighborhood_outline", "council_outline"];

  const [aggregateLayer, setAggregateLayer] = useState(
    "blockgroup_concentration"
  );
  const [outlineLayer, setOutlineLayer] = useState("council_outline");

  const activeOutlineLayer = (outlineLayer) => {
    if (outlineLayer === "council_outline") {
      return "council_layer";
    } else if (outlineLayer === "neighborhood_outline") {
      return "neighborhood_layer";
    }
  };

  const [showThreshold, setShowThreshold] = useState(false);
  const [showPublicOnly, setShowPublicOnly] = useState(false);

  const [isLayerVisible, setIsLayerVisible] = useState({
    coastal_zones: false,
    fire_risk: false,
    dash_lines: false,
    commuter_lines: false,
    parcel_layer: true,
    bus_lines: false,
    metro_lines: false,
  });

  const [activeKey, setActiveKey] = useState("0");
  const handleAccordionToggle = (eventKey) => {
    setActiveKey(eventKey);
  };

  const [showIntroModal, setShowIntroModal] = useState(true);
  const handleCloseIntroModal = () => setShowIntroModal(false);

  const [parcelAnalysisToggle, setParcelAnalysisToggle] = useState(false);

  const backgroundMask = LayerStyles.backgroundMask;

  function handleDownloadFilter(value) {
    let filterList = [];
    if (downloadFilter.includes(value)) {
      filterList = downloadFilter.filter((val) => val !== value);
    } else {
      filterList = [...downloadFilter, value];
    }
    let filterVar = buildFilterVar(filterList);
    setDownloadFilter(filterList);
    setDownloadVar(filterVar);
  }

  function handleFilterChange(value) {
    let filterList = [];
    if (priorityFilter.includes(value)) {
      filterList = priorityFilter.filter((val) => val !== value);
      setPriorityFilter(filterList);
    } else {
      filterList = [...priorityFilter, value];
      setPriorityFilter(filterList);
    }
    handleBlockGroupFilter(filterList);
  }

  const handleBlockGroupFilter = (filterList) => {
    let filterName = buildFilterVar(filterList);
    setBlockgroupFilter(filterName);
  };

  function titleCase(str) {
    str = str.toLowerCase().split(" ");
    for (var i = 0; i < str.length; i++) {
      str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
    }
    return str.join(" ");
  }

  const blockgroupColors = [
    "#eff3ff",
    "#bdd7e7",
    "#6baed6",
    "#3182bd",
    "#08519c",
  ];

  const priorityLegendMap = [
    ["Small Parcels", "rgb(105,105,105,0.3)"],
    ["Not Likely Suitable", "#fdbe85"],
    ["Low Suitability", "#fd8d3c"],
    ["Moderate Suitability", "#e6550d"],
    ["Highly Suitable", "#a63603"],
  ];

  const opportunityCategories = [
    ["Highest Resource", "#4a1486"],
    ["High Resource", "#6a51a3"],
    ["Moderate Resource", "#807dba"],
    ["Low Resource", "#9e9ac8"],
    ["High Segregation & Poverty", "#bcbddc"],
  ];

  const toggleBoundaries = (e) => {
    if (outlineLayer === e.target.id) {
      setOutlineLayer("none");
    } else {
      setOutlineLayer(e.target.id);
    }
  };

  const handleLayerChange = (e) => {
    if (
      aggregateLayer === "opportunity_layer" &&
      e.target.id === "opportunity_layer"
    ) {
      setAggregateLayer("blockgroup_concentration");
    } else if (aggregateLayers.includes(e.target.id)) {
      if (aggregateLayer === e.target.id) {
        setAggregateLayer("none");
      } else {
        setAggregateLayer(e.target.id);
      }
    } else if (outlineLayers.includes(e.target.id)) {
      toggleBoundaries(e);
    }
    if (outlineLayer === "council_outline") {
      setDownloadJuris(districts[0]);
    } else if (outlineLayer === "neighborhood_outline") {
      setDownloadJuris(neighborhoods[0]);
    }
    if (clickedAggregate) {
      const clickedAggregateState = { clicked: false };
      mapRef.current.getMap().setFeatureState(
        {
          source: clickedAggregate.source,
          sourceLayer: clickedAggregate.sourceLayer,
          id: clickedAggregate.id,
        },
        clickedAggregateState
      );
      setClickedAggregate(null);
    } else {
      const newVisibility = !isLayerVisible[e.target.id];
      setIsLayerVisible((prevState) => ({
        ...prevState,
        [e.target.id]: newVisibility,
      }));
    }
  };

  const hasParcelID = (layerArray) => {
    if (layerArray.length > 0) {
      for (let i = 0; i < layerArray.length; i++) {
        if (layerArray[i].layer.id === "parcel_layer") {
          return true;
        }
      }
    }
    return false;
  };

  function layerFinder(layers, layerName) {
    return layers.find((f) => f.layer.id === layerName);
  }

  const layerDataHandler = useCallback((feature) => {
    const layerData = {};
    if (feature.layer.id === "parcel_layer") {
      layerData.id = feature.id;
      layerData.parcelId = feature.properties.Assessor_P;
      layerData.LIHTC_elig = feature.properties.LIHTC_elig;
      layerData.LIHTC_BP = feature.properties.LIHTC_BP;
      layerData.AHMP = feature.properties.AHMP;
      layerData.AHSC_elig = feature.properties.AHSC_elig;
      layerData.AHSC_prior = feature.properties.AHSC_prior;
      layerData.MATCH = feature.properties.MATCH;
      layerData.TOC = feature.properties.TOC;
      layerData.OPP_ZONE = feature.properties.OPP_ZONE;
      layerData.PUB_OWN = feature.properties.PUB_OWN;
      layerData.parcelZone = feature.properties.General_Pl;
      layerData.parcelPublic =
        feature.properties.Publicly_O === "NO - Privately-Owned" ? false : true;
      layerData.parcelSize = feature.properties.Parcel_Siz;
      layerData.parcelCap = feature.properties.Total_Capa;
      layerData.incomeBucket = feature.properties.Income_Cat;
      layerData.parcelLIHTC =
        feature.properties.LIHTC_elig === 1 ? "Yes" : "No";
      layerData.parcelAHSC =
        feature.properties.AHSC_elig < 3
          ? "No"
          : feature.properties.AHSC_elig === 3
          ? "ICP Only"
          : "TOD & ICP";
      layerData.parcelPriority = feature.properties.suitability_score;
      layerData.parcelAddress = titleCase(feature.properties.Site_Addre);
      layerData.parcelThreshold = feature.properties.size_threshold;
      layerData.parcelFire = feature.properties.fire_risk ? "Yes" : "No";
      layerData.parcelCoastal = feature.properties.coastal ? "Yes" : "No";
      if (feature.properties.hasOwnProperty("new_du")) {
        layerData.capacity = feature.properties.new_du.toFixed();
      } else {
        layerData.capacity = null;
      }
      layerData.nearestStop = feature.properties.stop_name;
      if (feature.properties.hasOwnProperty("costs_per_unit_low")) {
        layerData.costPerUnitRange =
          "" +
          feature.properties.costs_per_unit_low.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 0,
            minimumFractionDigits: 0,
          }) +
          " - " +
          feature.properties.costs_per_unit_high.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 0,
            minimumFractionDigits: 0,
          });
      }
    } else if (feature.layer.id === "opportunity_layer") {
      layerData.oppCat = feature.properties.oppcat;
    } else if (feature.layer.id === "neighborhood_layer") {
      layerData.neighborhoodName = feature.properties.label;
    } else if (feature.layer.id === "blockgroup_aggregation") {
      layerData.parcelCount = feature.properties[blockGroupFilter];
      layerData.aggLIHTC = feature.properties.LIHTC_elig;
      layerData.aggAHSC = feature.properties.AHSC_elig;
      layerData.siteConcentration = feature.properties.blockGroupShare;
    } else if (feature.layer.id === "bus_lines") {
      layerData.bus_route = feature.properties.RouteName;
    } else if (feature.layer.id === "council_layer") {
      layerData.district = feature.properties.label;
      layerData.council_member = feature.properties.NAME;
      layerData.district_link = feature.properties.NLA_URL;
      layerData.districtInfo = feature.properties.District_Name;
    } else if (feature.layer.id === "metro_stops") {
      layerData.stopName = feature.properties.STATION;
    } else if (feature.layer.id === "dash_stops") {
      layerData.stopName = feature.properties.stopname;
    } else if (feature.layer.id === "commuter_stops") {
      layerData.stopName = feature.properties.stopname;
    } else if (feature.layer.id === "bus_stops") {
      layerData.stopName = feature.properties.stop_name;
    }
    return layerData;
  });

  const onHover = useCallback(
    (e) => {
      let layer_ids = e.features.map((feature) => feature.layer.id);
      setHoverLayers(layer_ids);

      if (e.features.length > 0) {
        const features = e.features;

        const coordinates = { longitude: e.lngLat.lng, latitude: e.lngLat.lat };
        const info = features.map((feature) => layerDataHandler(feature));
        const merged = Object.assign({}, ...info, coordinates);
        if (layer_ids.includes("la_city_blur")) {
          merged.insideBoundary = false;
        } else {
          merged.insideBoundary = true;
        }
        setHoverInfo(merged);
      } else {
        setHoverInfo(null);
        setHoverLayers([]);
      }
    },
    [layerDataHandler]
  );

  const [clickedParcel, setClickedParcel] = useState(null);
  const [clickedAggregate, setClickedAggregate] = useState(null);

  const FilterDiv = (props) => {
    const { label, onChange, filterState } = props;
    return (
      <Form.Group>
        <Form.Label
          style={{
            fontSize: "16px",
            marginBottom: "0",
          }}
        >
          {label}
        </Form.Label>
        <br />
        <Form.Check
          inline
          type="checkbox"
          name="group1"
          value={"Not Likely Suitable"}
          label={"Not Likely Suitable"}
          onChange={onChange}
          id={"priorityCheck0" + label}
          style={{ fontSize: "14px", width: "45%" }}
          checked={filterState.includes("Not Likely Suitable")}
        ></Form.Check>
        <Form.Check
          inline
          type="checkbox"
          name="group1"
          value={"Low Suitability"}
          label={"Low"}
          onChange={onChange}
          id={"priorityCheck1" + label}
          style={{ fontSize: "14px", width: "45%" }}
          checked={filterState.includes("Low Suitability")}
        ></Form.Check>
        <Form.Check
          inline
          name="group2"
          type="checkbox"
          value={"Moderate Suitability"}
          label={"Moderate"}
          onChange={onChange}
          id={"priorityCheck2" + label}
          style={{ fontSize: "14px", width: "45%" }}
          checked={filterState.includes("Moderate Suitability")}
        ></Form.Check>
        <Form.Check
          inline
          name="group2"
          type="checkbox"
          value={"Highly Suitable"}
          label={"Highly Suitable"}
          onChange={onChange}
          id={"priorityCheck3" + label}
          style={{ fontSize: "14px", width: "45%" }}
          checked={filterState.includes("Highly Suitable")}
        ></Form.Check>
      </Form.Group>
    );
  };

  const ParcelFilterDiv = () => {
    return (
      (viewState.zoom > 14 || aggregateLayer === "blockgroup_aggregation") && (
        <FilterDiv
          label={"Funding Suitability Filter"}
          onChange={(e) => handleFilterChange(e.target.value)}
          filterState={priorityFilter}
        />
      )
    );
  };

  const DownloadFilterDiv = () => {
    return (
      <FilterDiv
        label={"Parcel Download Filter"}
        onChange={(e) => handleDownloadFilter(e.target.value)}
        filterState={downloadFilter}
      />
    );
  };

  const costPerUnit = (feature) => {
    // This function is used to generate the cost per unit text for a parcel

    let nar = "";

    let totValue = feature.properties.total_value;
    let newDU = feature.properties.new_du;
    if (
      totValue === null ||
      totValue === undefined ||
      newDU === null ||
      newDU === undefined
    ) {
      nar =
        "There was insufficient data for this site to gather an accurate cost assessment.";
    } else {
      let newDUA = feature.properties.new_dua;
      let newDU30 = feature.properties.new_du_30;
      let costsPerUnitLow30 = feature.properties.costs_per_unit_low_lt30;
      let costsPerUnitHigh30 = feature.properties.costs_per_unit_high_lt30;
      let totalCostsHigh30 = feature.properties.total_costs_per_site_high_lt30;
      let totalCostsLow30 = feature.properties.total_costs_per_site_low_lt30;
      let maxDensity = feature.properties.Max_Densit;
      let costsPerUnitLow = feature.properties.costs_per_unit_low;
      let costsPerUnitHigh = feature.properties.costs_per_unit_high;
      let totalCostsHigh = feature.properties.total_costs_per_site_high;
      let totalCostsLow = feature.properties.total_costs_per_site_low;

      if (newDUA && newDUA < 30) {
        nar =
          "If this site was rezoned to allow 30 units per acre, then the site could deliver " +
          newDU30.toFixed(0) +
          " units and the estimated costs would change to: \n" +
          "Costs per unit can range from " +
          costsPerUnitLow30.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 0,
            minimumFractionDigits: 0,
          }) +
          " to " +
          costsPerUnitHigh30.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 0,
            minimumFractionDigits: 0,
          }) +
          ".\nTotal development costs for this site could range from " +
          totalCostsLow30.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 0,
            minimumFractionDigits: 0,
          }) +
          " to " +
          totalCostsHigh30.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 0,
            minimumFractionDigits: 0,
          });
      } else {
        nar =
          "Based on the Sites Inventory data on allowable density of " +
          maxDensity +
          ", we assume the site can deliver " +
          newDU.toFixed(0) +
          " units. Given a range of development costs and referencing total value based on assessor data, we believe:\n" +
          "Costs per unit can range from " +
          costsPerUnitLow.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 0,
            minimumFractionDigits: 0,
          }) +
          " to " +
          costsPerUnitHigh.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 0,
            minimumFractionDigits: 0,
          }) +
          ".\nTotal development costs for this site could range from " +
          totalCostsLow.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 0,
            minimumFractionDigits: 0,
          }) +
          " to " +
          totalCostsHigh.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 0,
            minimumFractionDigits: 0,
          }) +
          ".";
      }
    }
    return nar;
  };

  const onMapClick = useCallback(
    (e) => {
      if (clickedParcel) {
        const clickedParcelState = { clicked: false };
        e.target.setFeatureState(
          {
            source: clickedParcel.source,
            sourceLayer: clickedParcel.sourceLayer,
            id: clickedParcel.id,
          },
          clickedParcelState
        );
        setClickedParcel(null);
      }
      if (clickedAggregate) {
        const clickedAggregateState = { clicked: false };
        e.target.setFeatureState(
          {
            source: clickedAggregate.source,
            sourceLayer: clickedAggregate.sourceLayer,
            id: clickedAggregate.id,
          },
          clickedAggregateState
        );
        setClickedAggregate(null);
      }

      if (hasParcelID(e.features)) {
        const features = e.features;
        const info = features.map((feature) => layerDataHandler(feature));
        const merged = Object.assign({}, ...info);
        const parcelFeatures = layerFinder(features, "parcel_layer");
        const parcelCostPerUnit = costPerUnit(parcelFeatures);
        merged.costPerUnit = parcelCostPerUnit;
        setClickInfo(merged);
        setActiveKey("1");
        setClickedParcel(parcelFeatures);
        const clickedParcelState = { clicked: true };
        e.target.setFeatureState(
          {
            source: parcelFeatures.source,
            sourceLayer: parcelFeatures.sourceLayer,
            id: parcelFeatures.id,
          },
          clickedParcelState
        );
        let coords = e.lngLat;
        let map = mapRef.current.getMap();
        if (viewState.zoom < 15.5) {
          map.flyTo({
            center: [coords.lng, coords.lat],
            speed: 0.45,
            // speed: viewState.zoom <= 15 ? 0.65 : 0.25,
            zoom: 17,
          });
        }
      } else if (viewState.zoom < 14) {
        const aggregateFeatures = layerFinder(
          e.features,
          activeOutlineLayer(outlineLayer)
        );
        if (aggregateFeatures) {
          let coords = e.lngLat;
          let map = mapRef.current.getMap();
          if (viewState.zoom < 10) {
            map.flyTo({
              center: [coords.lng, coords.lat],
              speed: 0.3,
              // speed: viewState.zoom <= 15 ? 0.65 : 0.25,
              zoom: 11,
            });
          }

          setClickedAggregate(aggregateFeatures);
          const clickedAggregateState = { clicked: true };
          e.target.setFeatureState(
            {
              source: aggregateFeatures.source,
              sourceLayer: aggregateFeatures.sourceLayer,
              id: aggregateFeatures.id,
            },
            clickedAggregateState
          );
        }
      }
    },

    [
      clickedAggregate,
      clickedParcel,
      layerDataHandler,
      outlineLayer,
      viewState.zoom,
    ]
  );

  function showAggregate(layer) {
    if (isLayerVisible["parcel_layer"] && aggregateLayer === layer) {
      return true;
    }
    return false;
  }

  const ControlPanel = () => {
    return (
      <Form>
        {viewState.zoom < 14 && (
          <div style={{ paddingTop: "0px" }}>
            <Form.Label
              style={{
                fontSize: "16px",
                marginBottom: "0",
              }}
            >
              Summary Level Controls
            </Form.Label>
            <Form.Check
              type="radio"
              id="blockgroup_concentration"
              label="Suitable Sites Heatmap"
              onClick={handleLayerChange}
              defaultChecked={aggregateLayer === "blockgroup_concentration"}
              style={{ fontSize: "14px" }}
            />
            <Form.Check
              type="radio"
              id="blockgroup_aggregation"
              label="Identified Residential Sites"
              onClick={handleLayerChange}
              defaultChecked={aggregateLayer === "blockgroup_aggregation"}
              style={{ fontSize: "14px" }}
            />
            <hr style={{ marginTop: "0", marginBottom: "0" }} />
          </div>
        )}
        <ParcelFilterDiv />
        <hr style={{ marginTop: "0", marginBottom: "0" }} />

        <Form.Label
          style={{
            fontSize: "16px",
            marginBottom: "0",
          }}
        >
          Jurisdiction Control
        </Form.Label>
        <Form.Check
          type="radio"
          id="neighborhood_outline"
          label="Neighborhoods"
          onClick={handleLayerChange}
          defaultChecked={outlineLayer === "neighborhood_outline"}
          style={{ fontSize: "14px" }}
        />
        <Form.Check
          type="radio"
          id="council_outline"
          label="Council Districts"
          onClick={handleLayerChange}
          defaultChecked={outlineLayer === "council_outline"}
          style={{ fontSize: "14px" }}
        />
        <hr style={{ marginTop: "0", marginBottom: "0" }} />
        <Form.Label
          style={{
            fontSize: "16px",
            marginBottom: "0",
          }}
        >
          Map Overlays
        </Form.Label>
        <Form.Check
          type="switch"
          id="opportunity_layer"
          label="TCAC Opportunity Areas"
          onClick={handleLayerChange}
          defaultChecked={aggregateLayer === "opportunity_layer"}
          style={{ fontSize: "14px" }}
        />
        <Form.Check
          type="switch"
          id="coastal_zones"
          label="Coastal Zones"
          onClick={handleLayerChange}
          defaultChecked={isLayerVisible["coastal_zones"]}
          style={{ fontSize: "14px" }}
        />
        <Form.Check
          type="switch"
          id="fire_risk"
          label="High Fire Risk Zones"
          onClick={handleLayerChange}
          defaultChecked={isLayerVisible["fire_risk"]}
          style={{ fontSize: "14px" }}
        />
        <hr style={{ marginTop: "0", marginBottom: "0" }} />
        <Form.Label
          style={{
            fontSize: "16px",
            marginBottom: "0",
          }}
        >
          Transit Lines
        </Form.Label>

        <Form.Check
          type="switch"
          id="dash_lines"
          label="DASH Routes"
          onClick={handleLayerChange}
          defaultChecked={isLayerVisible["dash_lines"]}
          style={{ fontSize: "14px" }}
        />
        <Form.Check
          type="switch"
          id="commuter_lines"
          label="Commuter Express"
          onClick={handleLayerChange}
          defaultChecked={isLayerVisible["commuter_lines"]}
          style={{ fontSize: "14px" }}
        />

        <Form.Check
          type="switch"
          id="bus_lines"
          label="Bus Routes"
          onClick={handleLayerChange}
          defaultChecked={isLayerVisible["bus_lines"]}
          style={{ fontSize: "14px" }}
        />
        <Form.Check
          type="switch"
          id="metro_lines"
          label="Metro Rail"
          onClick={handleLayerChange}
          defaultChecked={isLayerVisible["metro_lines"]}
          style={{ fontSize: "14px" }}
        />
        <hr style={{ marginTop: "0", marginBottom: "0" }} />
        <Form.Label
          style={{
            fontSize: "16px",
            marginBottom: "0",
          }}
        >
          Map Settings
        </Form.Label>
        <Form.Check
          type="switch"
          id="popup_control"
          label="Popup Toggle"
          onClick={(e) => setShowPopup(!showPopup)}
          defaultChecked={showPopup}
          style={{ fontSize: "14px" }}
        />
        <Form.Check
          type="switch"
          id="legend_control"
          label="Legend Toggle"
          onClick={(e) => setShowLegend(!showLegend)}
          defaultChecked={showLegend}
          style={{ fontSize: "14px" }}
        />
        {viewState.zoom >= 14 && (
          <div>
            <Form.Check
              type="switch"
              id="threshold_toggle"
              label="Show Small Parcels"
              onClick={(e) => setShowThreshold(!showThreshold)}
              defaultChecked={showThreshold}
              style={{ fontSize: "14px" }}
            />
            <Form.Check
              type="switch"
              id="public_toggle"
              label="Show Only Publicly Owned Parcels"
              onClick={(e) => setShowPublicOnly(!showPublicOnly)}
              defaultChecked={showPublicOnly}
              style={{ fontSize: "14px" }}
            />
          </div>
        )}
        <Form.Check
          type="switch"
          id="satellite_toggle"
          label="Satellite View"
          onClick={(e) => handleStyleChange(e)}
          defaultChecked={mapStyle === "satellite"}
          style={{ fontSize: "14px" }}
        />
      </Form>
    );
  };

  return (
    <Container fluid className="container-fluid" style={{ padding: "10px" }}>
      <Row md={12} height="100vh">
        <IntroModal
          showIntroModal={showIntroModal}
          handleCloseIntroModal={handleCloseIntroModal}
          handleInfoModal={handleInfoModal}
        />
        <InfoModal
          showInfoModal={showInfoModal}
          handleCloseInfoModal={handleCloseInfoModal}
        />
        <Col md={3}>
          <Row style={{ padding: "10px" }}>
            <Col>
              <div>
                <h2>
                  Maximizing Opportunity
                  <br />
                  Site Tool
                </h2>
              </div>
              <Accordion
                // alwaysOpen
                activeKey={activeKey}
                onSelect={handleAccordionToggle}
                style={{
                  fontSize: "14px",
                  // maxHeight: "calc(100vh - 300px)",
                  // overflowY: "auto",
                }}
              >
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Control Panel</Accordion.Header>
                  <Accordion.Body
                    style={{
                      maxHeight: "calc(100vh - 350px)",
                      overflowY: "auto",
                    }}
                  >
                    <ControlPanel />
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>Parcel Detail</Accordion.Header>
                  <Accordion.Body
                    style={{
                      maxHeight: "calc(100vh - 350px)",
                      overflowY: "auto",
                    }}
                  >
                    <DataTable clickInfo={clickInfo} />
                    <div
                      style={{
                        textAlign: "center",
                        paddingBottom: "10px",
                      }}
                    >
                      <Button
                        variant="info"
                        // size="sm"
                        onClick={(e) =>
                          setParcelAnalysisToggle(!parcelAnalysisToggle)
                        }
                        disabled={clickInfo === null}
                      >
                        {parcelAnalysisToggle
                          ? "Hide Funding Analysis"
                          : "Show Funding Analysis"}
                      </Button>
                    </div>
                    {parcelAnalysisToggle && (
                      <ParcelAnalysisTable clickInfo={clickInfo} />
                    )}
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item>
                  <Accordion.Header>Download Data</Accordion.Header>
                  <Accordion.Body
                  // style={{
                  //   maxHeight: "calc(100vh - 200px)",
                  //   overflowY: "auto",
                  // }}
                  >
                    <p>Download Data by Neighborhood or Council District</p>
                    <Form.Label
                      style={{
                        fontSize: "16px",
                        marginBottom: "0",
                      }}
                    >
                      Jurisdiction Control
                    </Form.Label>
                    <Form.Check
                      type={"radio"}
                      id={"neighborhood_outline"}
                      label={"Neighborhoods"}
                      onChange={(e) => toggleBoundaries(e)}
                      checked={outlineLayer === "neighborhood_outline"}
                    ></Form.Check>
                    <Form.Check
                      type={"radio"}
                      id={"council_outline"}
                      label={"Council Districts"}
                      onChange={(e) => toggleBoundaries(e)}
                      checked={outlineLayer === "council_outline"}
                    ></Form.Check>
                    <DownloadFilterDiv />
                    <Form.Control
                      as="select"
                      disabled={outlineLayer === "none"}
                      value={downloadJuris}
                      onChange={(e) => {
                        setDownloadJuris(e.target.value);
                      }}
                    >
                      {outlineLayer === "council_outline" &&
                        districts.map((district) => (
                          <option key={district}>
                            {"District " + district}
                          </option>
                        ))}
                      {outlineLayer === "neighborhood_outline" &&
                        neighborhoods.map((neighborhood) => (
                          <option key={neighborhood}>{neighborhood}</option>
                        ))}
                      {outlineLayer === "none" && <option disabled></option>}
                    </Form.Control>
                    <div
                      style={{
                        textAlign: "center",
                        paddingTop: "10px",
                      }}
                    >
                      <Button
                        disabled={downloadVar === "" || outlineLayer === "none"}
                        variant="info"
                        size="sm"
                        href={
                          "./data/export/" +
                          downloadJuris.replace(" ", "_").replace("/", "-") +
                          "_" +
                          downloadVar +
                          ".csv"
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                        download={
                          downloadJuris.replace(" ", "_") +
                          "_" +
                          downloadVar +
                          ".csv"
                        }
                        align="center"
                      >
                        Download
                      </Button>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="info">
                  <Accordion.Header>Program Information</Accordion.Header>
                  <Accordion.Body>
                    <h4>Programs Modeled in MOST:</h4>
                    <a
                      href="https://www.huduser.gov/portal/datasets/lihtc.html"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Low-Income Housing Tax Credits (LIHTC)
                    </a>
                    <a
                      href="https://sgc.ca.gov/programs/ahsc/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <br />
                      Affordable Housing and Sustainable Communities (AHSC)
                    </a>
                    <a
                      href="https://housing.lacity.org/partners/affordable-housing-managed-pipeline"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <br />
                      Affordable Housing Managed Pipeline (AHMP)
                    </a>
                    <p></p>
                    <Button
                      onClick={(e) => {
                        setShowInfoModal(true);
                      }}
                      variant="info"
                    >
                      Methodology Information
                    </Button>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Col>
          </Row>
        </Col>
        <Col md={9}>
          <Row md={9} style={{ height: "90vh" }}>
            <Col style={{ padding: "10px 15px" }}>
              <Map
                {...viewState}
                maxZoom={17}
                minZoom={9}
                ref={mapRef}
                onMove={(e) => setViewState(e.viewState)}
                onMouseMove={onHover}
                onClick={onMapClick}
                mapStyle="mapbox://styles/lahd-mapbox/clnnm3szo00tt01q1ad3dbbbp"
                mapboxAccessToken={mapboxToken}
                interactiveLayerIds={LayerStyles.interactiveLayerIds}
                cursor={"pointer"}
              >
                <GeocoderControl
                  mapboxAccessToken={mapboxToken}
                  marker={true}
                  position="top-left"
                />
                <NavigationControl position="bottom-left"></NavigationControl>
                <Source
                  id="osit-tileset"
                  type="vector"
                  url="mapbox://lahd-mapbox.6f17djpr"
                  generateId={true}
                />
                <Source
                  id="background-blur"
                  type="geojson"
                  data={backgroundMask}
                  generateId={true}
                />
                <Source
                  id="metro-stops"
                  type="geojson"
                  data={metro_stops}
                  generateId={true}
                />
                <Source
                  id="dash-stops"
                  type="geojson"
                  data={dash_stops}
                  generateId={true}
                />
                <Source
                  id="dash-lines"
                  type="geojson"
                  data={dash_lines}
                  generateId={true}
                />
                <Source
                  id="commuter-stops"
                  type="geojson"
                  data={commuter_stops}
                  generateId={true}
                />
                <Source
                  id="commuter-lines"
                  type="geojson"
                  data={commuter_lines}
                  generateId={true}
                />
                <Source
                  id="bus-stops"
                  type="geojson"
                  data={bus_stops}
                  generateId={true}
                />
                <Source
                  id="neighborhood_labels"
                  type="geojson"
                  data={neighborhood_labels}
                  generateId={true}
                />

                <Source
                  id="council_labels"
                  type="geojson"
                  data={district_labels}
                  generateId={true}
                />

                <Layer
                  id="neighborhood_layer"
                  source="osit-tileset"
                  source-layer="neighborhoods"
                  {...LayerStyles.nonDynamicStyles["neighborhood_layer"]}
                />
                <Layer
                  id="council_layer"
                  source="osit-tileset"
                  source-layer="council-districts"
                  {...LayerStyles.nonDynamicStyles["council_layer"]}
                  beforeID={beforeID(mapStyle)}
                />
                <Layer
                  id="opportunity_layer"
                  source="osit-tileset"
                  source-layer="opportunity-layer"
                  {...LayerStyles.opportunity_layer_style(
                    isLayerVisible["opportunity_layer"],
                    aggregateLayer,
                    opportunityCategories
                  )}
                  beforeID={beforeID(mapStyle)}
                />

                <Layer
                  id="blockgroup_aggregation"
                  source="osit-tileset"
                  source-layer="blockgroup-aggregation"
                  {...LayerStyles.blockgroup_layer_style(
                    blockGroupFilter,
                    showAggregate,
                    priorityLegendMap,
                    priorityFilter,
                    blockgroupColors,
                    viewState
                  )}
                  beforeID={beforeID(mapStyle)}
                />
                <Layer
                  id="blockgroup_concentration"
                  source="osit-tileset"
                  source-layer="blockgroup-aggregation"
                  {...LayerStyles.blockgroup_concentration_layer(
                    showAggregate,
                    blockgroupColors,
                    viewState
                  )}
                  beforeID={beforeID(mapStyle)}
                />
                <Layer
                  id="coastal_zones"
                  source="osit-tileset"
                  source-layer="coastal-zones"
                  {...LayerStyles.coastal_layer_style(
                    isLayerVisible["coastal_zones"]
                  )}
                  beforeID={beforeID(mapStyle)}
                />
                <Layer
                  id="fire_risk"
                  source="osit-tileset"
                  source-layer="fire-risk"
                  {...LayerStyles.fire_layer_style(isLayerVisible["fire_risk"])}
                  beforeID={beforeID(mapStyle)}
                />
                <Layer
                  id="fire_risk_boundary"
                  source="osit-tileset"
                  source-layer="fire-risk"
                  {...LayerStyles.fire_boundary_style(
                    isLayerVisible["fire_risk"]
                  )}
                  beforeID={beforeID(mapStyle)}
                />
                <Layer
                  id="parcel_layer"
                  source="osit-tileset"
                  source-layer="parcels"
                  {...LayerStyles.parcel_layer_style(
                    priorityLegendMap,
                    priorityFilter,
                    showThreshold,
                    showPublicOnly
                  )}
                  beforeID={beforeID(mapStyle)}
                />
                {outlineLayer === "neighborhood_outline" && (
                  <Layer
                    id="label"
                    source="neighborhood_labels"
                    {...LayerStyles.label_layer(viewState.zoom < 14)}
                  />
                )}
                {outlineLayer === "council_outline" && (
                  <Layer
                    id="label"
                    source="council_labels"
                    {...LayerStyles.label_layer(viewState.zoom < 14)}
                  />
                )}

                <Layer
                  id="bus_lines"
                  source="osit-tileset"
                  source-layer="bus-lines"
                  {...LayerStyles.bus_line_style(isLayerVisible["bus_lines"])}
                  beforeID={beforeID(mapStyle)}
                />

                <Layer
                  id="la_city_blur"
                  source="background-blur"
                  {...LayerStyles.nonDynamicStyles["la_city_blur"]}
                  beforeID={beforeID(mapStyle)}
                />
                <Layer
                  id="neighborhood_outline"
                  source="osit-tileset"
                  source-layer="neighborhoods"
                  {...LayerStyles.neighborhood_outline_style(
                    outlineLayer === "neighborhood_outline"
                  )}
                  beforeID={beforeID(mapStyle)}
                />
                {clickedAggregate && (
                  <Layer
                    id="highlight"
                    type="line"
                    source="osit-tileset"
                    source-layer={clickedAggregate.sourceLayer}
                    paint={{
                      "line-color": [
                        "case",
                        ["boolean", ["feature-state", "clicked"], false],
                        "#FDDA0D",
                        "rgba(255, 255, 255, 0.0)",
                      ],
                      "line-width": 4,
                    }}
                    beforeID={beforeID(mapStyle)}
                  />
                )}
                <Layer
                  id="council_outline"
                  source="osit-tileset"
                  source-layer="council-districts"
                  {...LayerStyles.council_outline_style(
                    outlineLayer === "council_outline"
                  )}
                  beforeID={beforeID(mapStyle)}
                />
                <Layer
                  id="metro_lines"
                  source="osit-tileset"
                  source-layer="metro-lines"
                  {...LayerStyles.metro_line_style(
                    isLayerVisible["metro_lines"],
                    viewState
                  )}
                  beforeID={beforeID(mapStyle)}
                />
                <Layer
                  id="metro_stops"
                  source="metro-stops"
                  // source-layer="metro_stops"
                  {...LayerStyles.metro_stop_style(
                    isLayerVisible["metro_lines"],
                    viewState
                  )}
                  beforeID={beforeID(mapStyle)}
                />
                <Layer
                  id="commuter_lines"
                  source="commuter-lines"
                  {...LayerStyles.commuter_line_style(
                    isLayerVisible["commuter_lines"],
                    viewState
                  )}
                  beforeID={beforeID(mapStyle)}
                />
                <Layer
                  id="commuter_stops"
                  source="commuter-stops"
                  {...LayerStyles.commuter_stop_style(
                    isLayerVisible["commuter_lines"],
                    viewState
                  )}
                  beforeID={beforeID(mapStyle)}
                />
                <Layer
                  id="bus_stops"
                  source="bus-stops"
                  {...LayerStyles.bus_stop_style(
                    isLayerVisible["bus_lines"],
                    viewState
                  )}
                  beforeID={beforeID(mapStyle)}
                />
                <Layer
                  id="dash_lines"
                  source="dash-lines"
                  {...LayerStyles.dash_line_style(
                    isLayerVisible["dash_lines"],
                    viewState
                  )}
                  beforeID={beforeID(mapStyle)}
                />
                <Layer
                  id="dash_stops"
                  source="dash-stops"
                  {...LayerStyles.dash_stop_style(
                    isLayerVisible["dash_lines"],
                    viewState
                  )}
                  beforeID={beforeID(mapStyle)}
                />
                {hoverInfo &&
                showPopup &&
                !hoverLayers.includes("la_city_blur") ? (
                  <Popup
                    latitude={hoverInfo.latitude}
                    longitude={hoverInfo.longitude}
                    offset={50}
                    closeButton={false}
                    closeOnClick={false}
                    style={{
                      opacity: "0.9",
                    }}
                  >
                    <MapPopupDivs hoverInfo={hoverInfo} />
                  </Popup>
                ) : null}
                <Legend
                  visible={showLegend}
                  clickInfo={clickInfo}
                  clickedAggregate={clickedAggregate}
                  opportunityCategories={opportunityCategories}
                  blockgroupColors={blockgroupColors}
                  priorityFilter={priorityFilter}
                  priorityLegendMap={priorityLegendMap}
                  viewState={viewState}
                  aggregateLayer={aggregateLayer}
                  showThreshold={showThreshold}
                  isLayerVisible={isLayerVisible}
                ></Legend>
              </Map>
            </Col>
          </Row>
          <Row>
            <Col md={6} style={{ textAlign: "left" }}>
              <a href="https://housing.lacity.org/">
                Created for the Los Angeles Housing Department
                <img
                  id="lahd-logo"
                  className="footer"
                  src={lahd_logo}
                  alt="Los Angeles Housing Department Logo."
                  height={"40px"}
                ></img>
              </a>
            </Col>
            <Col md={6} style={{ textAlign: "right" }}>
              <a href="https://econw.com/">
                Technology by ECONorthwest
                <img
                  id="econw-logo"
                  className="footer"
                  src={econw_logo}
                  alt="ECONorthwest Logo."
                  height={"40px"}
                ></img>
              </a>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}
