import * as turf from "@turf/turf";
import la_city_boundary from "../data/la_city_boundary.json";

let world = [
  [
    [180, 90],
    [-180, 90],
    [-180, -90],
    [180, -90],
    [180, 90],
  ],
];

const backgroundMask = turf.difference(
  turf.polygon(world),
  turf.polygon(la_city_boundary.features[0].geometry.coordinates)
);

const interactiveLayerIds = [
  "parcel_layer",
  "opportunity_layer",
  "neighborhood_layer",
  "blockgroup_aggregation",
  "metro_lines",
  "metro_stops",
  "bus_lines",
  "la_city_blur",
  "council_outline",
  "council_layer",
  "dash_stops",
  "dash_lines",
  "commuter_lines",
  "commuter_stops",
  "bus_stops",
];

const coastal_layer_style = (visible) => {
  let style = {
    id: "coastal",
    type: "fill",
    layout: {
      visibility: visible ? "visible" : "none",
    },
    paint: {
      "fill-color": "rgba(0,102,204,0.5)",
      "fill-outline-color": "black",
    },
  };
  return style;
};

const fire_boundary_style = (visible) => {
  let style = {
    id: "fire-boundary",
    type: "line",
    layout: {
      visibility: visible ? "visible" : "none",
    },
    paint: {
      "line-color": "orange",
      // "line-color": "rgba(255, 0, 0, 1)",
      "line-width": 3,
      // "fill-outline-color": "black",
    },
  };
  return style;
};

const fire_layer_style = (visible) => {
  let style = {
    id: "fire",
    type: "fill",
    layout: {
      visibility: visible ? "visible" : "none",
    },
    paint: {
      "fill-color": "rgba(255, 0, 0, 0.1)",
    },
  };
  return style;
};

const label_layer = (visible) => {
  let style = {
    id: "labels",
    type: "symbol",
    layout: {
      visibility: visible ? "visible" : "none",
      "text-field": ["get", "label"],
    },
    paint: {
      "text-color": "black",
      "text-halo-color": "white",
      "text-halo-width": 2,
    },
  };
  return style;
};

const transit_layer_style = (visible) => {
  let style = {
    id: "transit_stations",
    type: "circle",
    layout: {
      visibility: visible ? "visible" : "none",
    },
    paint: {
      "circle-color": "black",
    },
  };
  return style;
};

const neighborhood_outline_style = (visible) => {
  let style = {
    id: "neighborhood_outline",
    type: "line",
    layout: {
      visibility: visible ? "visible" : "none",
    },
    paint: {
      "line-color": "black",
      "line-width": 1,
    },
  };
  return style;
};

const council_outline_style = (visible) => {
  let style = {
    id: "council_outline",
    type: "line",
    layout: {
      visibility: visible ? "visible" : "none",
    },
    paint: {
      "line-color": "black",
      "line-width": 1,
    },
  };
  return style;
};

const opportunity_layer_style = (
  visible,
  aggregateLayer,
  opportunityCategories
) => {
  let style = {
    id: "opportunity_layer",
    type: "fill",
    layout: {
      visibility: "visible",
    },
    paint: {
      "fill-color": [
        "match",
        ["get", "oppcat"],
        "None",
        "#FFFFFF",
        opportunityCategories[0][0],
        opportunityCategories[0][1],
        opportunityCategories[1][0],
        opportunityCategories[1][1],
        opportunityCategories[2][0],
        opportunityCategories[2][1],
        opportunityCategories[3][0],
        opportunityCategories[3][1],
        opportunityCategories[4][0],
        opportunityCategories[4][1],
        "#FFFFFF",
      ],
      "fill-opacity": aggregateLayer === "opportunity_layer" ? 0.7 : 0,
    },
  };
  return style;
};

const blockgroup_concentration_layer = (
  showAggregate,
  blockgroupColors,
  viewState
) => {
  let style = {
    id: "blockgroup_concentration",
    type: "fill",
    layout: {
      visibility: showAggregate("blockgroup_concentration")
        ? "visible"
        : "none",
    },
    paint: {
      "fill-color": [
        "interpolate",
        ["exponential", 0.5],
        ["get", "blockGroupShare2"],
        0,
        blockgroupColors[0],
        0.03,
        blockgroupColors[1],
        0.07,
        blockgroupColors[2],
        0.2,
        blockgroupColors[3],
        1.0,
        blockgroupColors[4],
      ],
      "fill-opacity": [
        "case",
        [">", ["get", "blockGroupShare"], 0],
        viewState.zoom >= 14 ? 0 : 0.75,
        0,
      ],
    },
  };
  return style;
};

const blockgroup_layer_style = (
  blockGroupFilter,
  showAggregate,
  priorityLegendMap,
  priorityFilter,
  blockgroupColors,
  viewState
) => {
  const paintStyler = (blockGroupFilter) => {
    let paintStyle = {
      "fill-opacity": 0,
    };
    if (blockGroupFilter !== "") {
      paintStyle = {
        "fill-color": [
          "interpolate",
          ["exponential", 0.5],
          ["get", blockGroupFilter],
          0,
          blockgroupColors[0],
          10,
          blockgroupColors[1],
          25,
          blockgroupColors[2],
          35,
          blockgroupColors[3],
          50,
          blockgroupColors[4],
        ],

        "fill-opacity": [
          "case",
          [">", ["get", blockGroupFilter], 0],
          viewState.zoom >= 14 ? 0 : 0.75,
          0,
        ],
      };
    }
    return paintStyle;
  };

  let style = {
    id: "blockgroup_aggregation",
    type: "fill",
    layout: {
      visibility: showAggregate("blockgroup_aggregation") ? "visible" : "none",
    },
    paint: paintStyler(blockGroupFilter),
  };
  return style;
};

const parcel_layer_style = (
  priorityLegendMap,
  priorityFilter,
  showThreshold,
  showPublicOnly
) => {
  const parcelStyler = (showThreshold, showPublicOnly) => {
    let suitabilityLogic = [
      "case",
      ["in", ["get", "suitability_score"], ["literal", priorityFilter]],
      [
        "match",
        ["get", "suitability_score"],
        priorityLegendMap[1][0],
        priorityLegendMap[1][1],
        priorityLegendMap[2][0],
        priorityLegendMap[2][1],
        priorityLegendMap[3][0],
        priorityLegendMap[3][1],
        priorityLegendMap[4][0],
        priorityLegendMap[4][1],
        "#FFF",
      ],
      "rgb(105, 105, 105)",
    ];

    if (showThreshold && !showPublicOnly) {
      // all parcels get painted based on their suitability score
      // ignore size threshold and public ownership
      return suitabilityLogic;
    } else if (!showThreshold && !showPublicOnly) {
      // all small parcels get painted grey
      // large parcels get painted based on their suitability score
      // public ownership is ignored
      return [
        "case",
        ["==", ["get", "size_threshold"], true],
        suitabilityLogic,
        "rgb(105, 105, 105)",
      ];
    } else if (showThreshold && showPublicOnly) {
      // only public parcels get painted based on their suitability score
      // ignore size threshold
      return [
        "case",
        ["==", ["get", "public_ownership"], true],
        suitabilityLogic,
        "rgb(105, 105, 105)",
      ];
    } else if (!showThreshold && showPublicOnly) {
      // all small parcels get painted grey
      // all private parcels get painted grey
      // only large publicly owned parcels get painted based on their suitability score
      return [
        "case",
        [
          "all",
          ["==", ["get", "public_ownership"], true],
          ["==", ["get", "size_threshold"], true],
        ],
        suitabilityLogic,
        "rgb(105, 105, 105)",
      ];
    }
  };

  const parcelOpacityHandler = (showThreshold, showPublicOnly) => {
    let suitabilityOpacity = [
      "case",
      ["in", ["get", "suitability_score"], ["literal", priorityFilter]],
      0.75,
      0.3,
    ];

    if (showThreshold && !showPublicOnly) {
      // standard opacity, no threshold or public ownership
      return [
        "case",
        ["boolean", ["feature-state", "clicked"], false],
        1,
        suitabilityOpacity,
      ];
    } else if (!showThreshold && !showPublicOnly) {
      // all small parcels are transparent
      return [
        "case",
        ["boolean", ["feature-state", "clicked"], false],
        1,
        [
          "case",
          ["==", ["get", "size_threshold"], false],
          0.3,
          suitabilityOpacity,
        ],
      ];
    } else if (showThreshold && showPublicOnly) {
      return [
        "case",
        ["boolean", ["feature-state", "clicked"], false],
        1,
        [
          "case",
          ["==", ["get", "public_ownership"], false],
          0.3,
          suitabilityOpacity,
        ],
      ];
    } else if (!showThreshold && showPublicOnly) {
      return [
        "case",
        ["boolean", ["feature-state", "clicked"], false],
        1,
        [
          "case",
          [
            "all",
            ["==", ["get", "public_ownership"], true],
            ["==", ["get", "size_threshold"], true],
          ],
          suitabilityOpacity,
          0.3,
        ],
      ];
    }
  };

  let style = {
    id: "parcel_layer",
    type: "fill",
    // filter: ["in", ["get", "suitability_score"], ["literal", priorityFilter]],

    layout: {
      visibility: "visible",
    },

    paint: {
      // if showThreshold is false, paint polygons that are small grey and then paint the rest of the polygons based on the priorityFilter
      // if showThreshold is true, paint all polygons based on the priorityFilter
      "fill-color": parcelStyler(showThreshold, showPublicOnly),
      "fill-opacity": parcelOpacityHandler(showThreshold, showPublicOnly),

      //   ["let", "showThreshold", ["literal", showThreshold]],
      //   "case",
      //   ["!", ["var", "showThreshold"]],
      //   [
      //     "case",
      //     ["==", ["get", "size_threshold"], false],
      //     "rgba(105, 105, 105, 0.3)", // If size_threshold is false, paint the polygon grey
      //     parcelStyler,
      //   ],
      //   parcelStyler,
      // ],
      // ]

      // "fill-color": [
      //   "case",
      //   [
      //     "!",
      //     ["var", ["literal", showThreshold]],
      //     [
      //       "case",
      //       ["==", ["get", "size_threshold"], false],
      //       ["rgb(105, 105, 105)"], // If size_threshold is false, paint the polygon grey
      //       parcelStyler,
      //     ],
      //   ],
      //   parcelStyler,]

      // ],
      // ["==", ["get", "size_threshold"], false],
      // "rgb(105, 105, 105)", // If size_threshold is false, paint the polygon grey
      // parcelStyler,

      // [
      //   "match",
      //   ["get", "suitability_score"],

      //   priorityLegendMap[1][0],
      //   priorityLegendMap[1][1],
      //   priorityLegendMap[2][0],
      //   priorityLegendMap[2][1],
      //   priorityLegendMap[3][0],
      //   priorityLegendMap[3][1],
      //   priorityLegendMap[4][0],
      //   priorityLegendMap[4][1],
      //   "#FFF",
      // ],
      /// if size_threshold is true, paint the polygon based on the priorityFilter
      // [
      //   "case",
      //   ["in", ["get", "suitability_score"], ["literal", priorityFilter]],
      //   [
      //     "match",
      //     ["get", "suitability_score"],

      //     priorityLegendMap[1][0],
      //     priorityLegendMap[1][1],
      //     priorityLegendMap[2][0],
      //     priorityLegendMap[2][1],
      //     priorityLegendMap[3][0],
      //     priorityLegendMap[3][1],
      //     priorityLegendMap[4][0],
      //     priorityLegendMap[4][1],
      //     "#FFF",
      //   ],
      //   "rgba(105, 105, 105, 0.3)",
      // ],
      // ],
      // "fill-opacity": [
      //   "case",
      //   ["boolean", ["feature-state", "clicked"], false],
      //   1,
      //   0.8,
      //   // ["case", ["==", ["get", "size_threshold"], false], 0.3, 0.8],
      // ],
      "fill-outline-color": [
        "case",
        ["boolean", ["feature-state", "clicked"], false],
        "black",
        "rgba(255, 255, 255, 0.3)",
      ],
    },
  };
  return style;
};

// const community_dash_style = (visible) => {
//   let style = {
//     id: "community_dash_stops",
//     type: "circle",
//     layout: {
//       visibility: visible ? "visible" : "none",
//     },
//     paint: {
//       "circle-radius": 5,
//       "circle-color": "black",
//     },
//     cluster: false,
//   };
//   return style;
// };

const metro_line_style = (visible, viewState) => {
  let style = {
    id: "metro_lines",
    type: "line",
    layout: {
      visibility: visible ? "visible" : "none",
    },
    paint: {
      "line-width": viewState.zoom < 12 ? 6 : 4,
      "line-color": ["get", "lineColor"],
    },
    // cluster: false,
  };
  return style;
};

const metro_stop_style = (visible, viewState) => {
  let style = {
    id: "metro_stops",
    type: "circle",
    layout: {
      visibility: visible ? "visible" : "none",
    },
    paint: {
      "circle-radius": viewState.zoom < 12 ? 7 : 6,
      "circle-color": ["get", "lineColor"],
      "circle-opacity": viewState.zoom > 10 ? 1 : 0,
      "circle-stroke-color": "black",
      "circle-stroke-opacity": viewState.zoom > 10 ? 1 : 0,
      "circle-stroke-width": 1,
    },
    cluster: false,
  };
  return style;
};

const commuter_line_style = (visible, viewState) => {
  let style = {
    id: "commuter_lines",
    type: "line",
    layout: {
      visibility: visible ? "visible" : "none",
    },
    paint: {
      "line-width": viewState.zoom < 12 ? 6 : 4,
      "line-color": "rgba(49,163,84,0.7)",
    },
  };
  return style;
};

const commuter_stop_style = (visible, viewState) => {
  let style = {
    id: "commuter_stops",
    type: "circle",
    layout: {
      visibility: visible ? "visible" : "none",
    },
    paint: {
      "circle-radius": viewState.zoom < 12 ? 7 : 6,
      "circle-color": "rgba(49,163,84,0.7)",
      "circle-opacity": viewState.zoom > 10 ? 1 : 0,
      "circle-stroke-color": "black",
      "circle-stroke-opacity": viewState.zoom > 10 ? 1 : 0,
      "circle-stroke-width": 1,
    },
    cluster: false,
  };
  return style;
};

const bus_stop_style = (visible, viewState) => {
  let style = {
    id: "bus_stops",
    type: "circle",
    layout: {
      visibility: visible ? "visible" : "none",
    },
    paint: {
      "circle-radius": viewState.zoom < 16 ? 7 : 6,
      "circle-color": "#696969",
      "circle-opacity": viewState.zoom > 14 ? 1 : 0,
      "circle-stroke-color": "black",
      "circle-stroke-opacity": viewState.zoom > 14 ? 1 : 0,
      "circle-stroke-width": 1,
    },
    cluster: false,
  };
  return style;
};

const dash_line_style = (visible, viewState) => {
  let style = {
    id: "dash_lines",
    type: "line",
    layout: {
      visibility: visible ? "visible" : "none",
    },
    paint: {
      "line-width": viewState.zoom < 12 ? 6 : 4,
      "line-color": [
        "match",
        ["get", "regiontype"],
        "Community Dash",
        "rgb(252,141,98)",
        "Downtown Dash",
        "rgb(255, 255, 179)",
        "black",
      ],
    },
  };
  return style;
};

const dash_stop_style = (visible, viewState) => {
  let style = {
    id: "dash_stops",
    type: "circle",
    layout: {
      visibility: visible ? "visible" : "none",
    },
    paint: {
      "circle-radius": viewState.zoom < 12 ? 7 : 6,
      "circle-color": [
        "match",
        ["get", "regiontype"],
        "Community Dash",
        "rgb(252,141,98)",
        "Downtown Dash",
        "rgb(255, 255, 179)",
        "black",
      ],
      "circle-opacity": viewState.zoom > 12 ? 1 : 0,
      "circle-stroke-color": "black",
      "circle-stroke-opacity": viewState.zoom > 12 ? 1 : 0,
      "circle-stroke-width": 1,
    },
    cluster: false,
  };
  return style;
};

const bus_line_style = (visible) => {
  let style = {
    id: "bus_lines",
    type: "line",
    layout: {
      visibility: visible ? "visible" : "none",
    },
    paint: {
      "line-width": 2,
      "line-color": "#696969",
    },
  };
  return style;
};

const nonDynamicStyles = {
  la_city_boundary: {
    id: "la_city_boundary",
    type: "line",
    layout: {
      visibility: "visible",
    },
    paint: {
      "line-color": "#696969",
      "line-width": 1,
    },
  },
  la_city_blur: {
    id: "la_city_blur",
    type: "fill",
    layout: {
      visibility: "visible",
    },
    paint: {
      // "fill-opacity": 0.5,
      "fill-opacity": ["case", ["within", backgroundMask], 0, 0.5],
      "fill-color": "#696969",
    },
  },
  neighborhood_layer: {
    id: "neighborhood_layer",
    type: "fill",
    layout: {
      visibility: "visible",
    },
    paint: {
      "fill-opacity": 0,
    },
  },
  council_layer: {
    id: "council_layer",
    type: "fill",
    layout: {
      visibility: "visible",
    },
    paint: {
      "fill-opacity": 0,
    },
  },
};

export {
  backgroundMask,
  blockgroup_concentration_layer,
  blockgroup_layer_style,
  bus_line_style,
  bus_stop_style,
  coastal_layer_style,
  commuter_line_style,
  commuter_stop_style,
  council_outline_style,
  dash_line_style,
  dash_stop_style,
  fire_boundary_style,
  fire_layer_style,
  interactiveLayerIds,
  label_layer,
  metro_line_style,
  metro_stop_style,
  neighborhood_outline_style,
  nonDynamicStyles,
  opportunity_layer_style,
  parcel_layer_style,
  transit_layer_style,
};
