import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

const IntroModal = (props) => {
  const { showIntroModal, handleCloseIntroModal, handleInfoModal } = props;

  return (
    <Modal show={showIntroModal} onHide={handleCloseIntroModal} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>
          <h3 style={{ textAlign: "center" }}>
            Welcome to the Los Angeles Maximizing Opportunity Site Tool!
          </h3>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p style={{ textAlign: "justify" }}>
          The Maximizing Opportunity Site Tool (MOST) uses various affordable
          housing funding criteria (such as Low-Income Housing Tax Credits
          (LIHTC), Affordable Housing and Sustainable Communities (AHSC), and
          the Affordable Housing Managed Pipeline (AHMP). The tool uses local
          data to inform where sites might be more competitive in acquiring
          funding based on geographic location and nearby
          infrastructure/amenities.
        </p>
      </Modal.Body>
      <Button onClick={handleInfoModal} size="sm" variant="info">
        Methodology Information
      </Button>
    </Modal>
  );
};

const InfoModal = (props) => {
  const { showInfoModal, handleCloseInfoModal } = props;
  return (
    <Modal
      show={showInfoModal}
      onHide={handleCloseInfoModal}
      size="lg"
      scrollable={true}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h1 style={{ textAlign: "center", color: "rgb(51,70,116)" }}>
            About{" "}
          </h1>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p style={{ textAlign: "justify" }}>
          The Maximizing Opportunity Site Tool (MOST) is a web-based platform
          that provides information on residential sites in the City of Los
          Angeles. It analyzes spatial criteria used in scoring formulas for
          major affordable housing funding programs alongside other factors that
          are supportive of affordable housing development. The tool allows
          users to observe sites categorized by their suitability for affordable
          housing funding based on spatial characteristics.
        </p>
        <p>The tool includes spatial scoring criteria for:</p>
        <ul>
          <li>Low-Income Housing Tax Credits (LIHTC)</li>
          <li>
            The City of Los Angeles' Affordable Housing Managed Pipeline (AHMP)
          </li>
          <li>Affordable Housing and Sustainable Communities (AHSC)</li>
          <li>Transit Oriented Communities Program (TOC)</li>
        </ul>
        <p>
          The base residential site layer is the City's 6th Cycle Housing
          Element Sites Inventory. Because subsidized affordable housing
          development is not likely to occur on small sites without scale, only
          sites that meet a minimum size threshold of 20,000 square feet are
          included in the summary level analysis.
        </p>
        <hr />

        <h2 style={{ color: "rgb(51,70,116)" }}>Scoring Tiers</h2>
        <p>
          All sites are categorized into one of four categories based on the
          presence of positive spatial criteria:
        </p>
        <table style={{ border: "1px solid black" }}>
          <tbody style={{ border: "1px solid black" }}>
            <tr style={{ border: "1px solid black" }}>
              <td style={{ border: "1px solid black" }}>Not Likely Suitable</td>
              <td>
                The site does not score max points for LIHTC and would not
                likely receive affordable housing funding.
              </td>
            </tr>
            <tr style={{ border: "1px solid black" }}>
              <td style={{ border: "1px solid black" }}>Low Suitability</td>
              <td>
                The site scores max points for LIHTC but would not score well on
                any other program criteria.
              </td>
            </tr>
            <tr style={{ border: "1px solid black" }}>
              <td style={{ border: "1px solid black" }}>
                Moderate Suitability
              </td>
              <td>
                The site scores max points for LIHTC and would also score points
                on other program criteria.
              </td>
            </tr>
            <tr style={{ border: "1px solid black" }}>
              <td style={{ border: "1px solid black" }}>Highly Suitable</td>
              <td>
                The site scores max points for LIHTC and would score well on
                most other program criteria. The site may have other favorable
                characteristics like public ownership, location in an
                Opportunity Zone, or in a high TOC Tier.
              </td>
            </tr>
          </tbody>
        </table>
        <hr />
        <h2 style={{ color: "rgb(51,70,116)" }}>Cost Per Unit Analysis</h2>
        <p>
          Site level data includes a metric that calculates the cost of
          development per unit based on maximum base density. Costs in this
          analysis include estimates of hard and soft costs for a prototypical
          development and the cost of acquisition. The cost of acquisition can
          vary broadly based on the existing use.
        </p>
        <hr />

        <h2 style={{ color: "rgb(51,70,116)" }}>Using the Tool</h2>
        <p>
          The tool offers the user two data summarizes in the same platform.
          When zoomed out, the summary level analysis presents Census Tracts
          scored by their concentration of sites that score in the high and
          moderate suitability tiers. This view level includes a blue colorway.
          When zoomed in, the parcel level data allows the user to select any
          parcel in the model and observe site-level data. This view level
          includes an orange colorway.
        </p>
        <hr />

        <h2 style={{ color: "rgb(51,70,116)" }}>Controls</h2>
        <p>
          The navigation panel on the left-hand side of the tool allows the user
          to toggle data and overlays including transit lines, fire risk zones,
          coastal zones, and TCAC opportunity areas. It also allows the user to
          toggle between neighborhood and council district geographies.
        </p>
        <hr />

        <h2 style={{ color: "rgb(51,70,116)" }}>Map Settings</h2>
        <table style={{ border: "1px solid black" }}>
          <tbody>
            <tr style={{ border: "1px solid black" }}>
              <td style={{ border: "1px solid black" }}>
                <b>Popup Toggle</b>
              </td>
              <td style={{ border: "1px solid black" }}>
                Enables a data summary window when a cursor hovers over a map
                element.
              </td>
            </tr>
            <tr style={{ border: "1px solid black" }}>
              <td style={{ border: "1px solid black" }}>
                <b>Legend Toggle</b>
              </td>
              <td style={{ border: "1px solid black" }}>
                Adds or removes the map legend.
              </td>
            </tr>
            <tr style={{ border: "1px solid black" }}>
              <td style={{ border: "1px solid black" }}>
                <b>Show Small Parcels</b>
              </td>
              <td style={{ border: "1px solid black" }}>
                Adds or removes sites below the 20,000 sq. ft. threshold from
                the map extent.
              </td>
            </tr>
            <tr style={{ border: "1px solid black" }}>
              <td style={{ border: "1px solid black" }}>
                <b>Show Only Publicly Owned Parcels</b>
              </td>
              <td style={{ border: "1px solid black" }}>
                Limits the map extent to show only sites that are owned by the
                City of Los Angeles.
              </td>
            </tr>
            <tr style={{ border: "1px solid black" }}>
              <td style={{ border: "1px solid black" }}>
                <b>Satellite View</b>
              </td>
              <td style={{ border: "1px solid black" }}>
                Toggles between a satellite and street map view.
              </td>
            </tr>
          </tbody>
        </table>
        <hr />
        <h2 style={{ color: "rgb(51,70,116)" }}>Limitations and Caveats</h2>
        <p>
          The Maximizing Opportunity Site Tool (MOST) has the following
          limitations and caveats:
        </p>
        <ul>
          <li>
            <b>Non-spatial criteria is important.</b> Spatial criteria make up
            only a portion of scoring criteria for most affordable housing
            funding programs. Other project-specific criteria should be
            considered when evaluating sites.
          </li>
          <li>
            <b>Base data is limited to Housing Element sites. </b>Because the
            tool is built off the City's Housing Element site inventory, data is
            not calculated for sites that were not in the inventory or sites
            that do not allow residential uses.
          </li>
          <li>
            <b>Zoned capacity does not consider density bonuses. </b>The
            calculation of zoned capacity is based on allowed density in the
            base zone. The analysis does not consider the range of density
            bonuses that could be available.
          </li>
          <li>
            <b>Parcel-level analysis does not consider site assemblage. </b>
            The tool is a parcel-level analysis and does not consider instances
            where multiple adjacent parcels could be assembled into a
            development site.
          </li>
          <li>
            <b>The tool is not a test of financial feasibility. </b>The tool
            only identifies the likelihood of sites to score points on spatial
            scoring criteria. It does not consider the financial feasibility of
            a potential development or an existing use as an impediment to
            redevelopment.
          </li>
        </ul>
      </Modal.Body>
    </Modal>
  );
};

export { InfoModal, IntroModal };
