const MapPopupDivs = (props) => {
  let hoverInfo = props.hoverInfo;
  return (
    <div
      offset={50}
      style={{
        opacity: "0.9",
      }}
    >
      <div>
        {hoverInfo.parcelId ? (
          <p>
            <strong>Site Address</strong>: {hoverInfo.parcelAddress}
            <br />
            <strong>Parcel Size: </strong> {hoverInfo.parcelSize.toFixed(2)}{" "}
            {" Acres"}
            <br />
            <strong>Parcel Capacity: </strong> {hoverInfo.parcelCap.toFixed(2)}
            <br />
            <strong>Zoning</strong>: {hoverInfo.parcelZone}
            <br />
            <strong>LIHTC Eligible?</strong> {hoverInfo.parcelLIHTC}
            <br />
            <strong>AHSC Eligible?</strong> {hoverInfo.parcelAHSC}
            <br />
            <strong>Funding Rating:</strong> {hoverInfo.parcelPriority}
          </p>
        ) : (
          <p>Hover over a Parcel for more info.</p>
        )}
        {hoverInfo.district ? (
          <p>
            <strong>City Council District</strong>
            {": "}
            {hoverInfo.district}
            <br />
            <strong>Council Member</strong>
            {": "}
            {hoverInfo.council_member}
          </p>
        ) : null}
        {hoverInfo.neighborhoodName ? (
          <div>
            <strong>Neighborhood</strong>
            {": "}
            {hoverInfo.neighborhoodName}
            {hoverInfo.oppCat ? (
              <p>
                {" "}
                <strong>Opportunity Category</strong>
                {": "}
                {hoverInfo.oppCat}
              </p>
            ) : null}
          </div>
        ) : null}
        {hoverInfo.stopName ? (
          <p>
            <strong>Stop Name</strong>: {hoverInfo.stopName}
          </p>
        ) : null}
      </div>
    </div>
  );
};

export { MapPopupDivs };
